.authContainer {
  width: 80%;
  max-width: 320px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.authContainer input{
  border: 1px solid lightgray;
  width: 100%;
  margin-bottom: 12px;
  padding: 6px 15px;
  border-radius: 5px;
}
.authContainer button{
  width: 100%;
  border-radius: 2px;
}