.headerVideo{
  width: 100%;
}

.headerContainer{
  position:  relative;
  margin-top: 68px;
}
.header_content{
  position: absolute;
  top: 20%;
  color: white;
  width: 100%;
}
.header_content > div{
  width:50%;
  margin: auto;
  /* background-color: rgba(0, 0, 0, 0.15); */
  /* box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.15), -5px -5px 60px rgba(0, 0, 0, 0.15); */
  /* border-radius: 30px; */
  padding: 30px;
}


.highlighted_text{
  color: #037c03;
  padding: 0 20px 5px;
  margin-top: 20px;
  display: inline-block;
}

@media screen and (max-width:767px) {
  .header_content h1{
    font-size: 5vw;
  }
  .header_content{
    top: 0
  }
}