.brokerContainer{
  padding: 50px 0;
  background-color: #eeeee4;
  
}

@media screen and (max-width:767px) {
  .broker__right{
    margin-bottom: 50px
  }
 
}