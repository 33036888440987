.cgprofile {
  color: var(--primary-color);
  font-size: 25px;
  display: none;
}s
.ioexitoutline{
 display: none;
}

@media screen and (min-width: 992px){
  .cgprofile {
    margin-left: 30px;
    display: inline;
  }
  .ioexitoutline{
    display: inline;
    color: gray;
    font-size: 20px;
  }
  .user{
    margin-left: 40px;
  }
}